<template>
  <!-- 定制班线订单 -->
  <div ref="customizedLineOrder" class="customizedLineOrder">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="订单编号：">
          <el-input
            v-model="queryForm.id"
            clearable
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="起点站：">
          <el-select
            v-model="queryForm.startStationNames"
            collapse-tags
            multiple
            clearable
            placeholder="请选择起点站"
          >
            <el-option
              v-for="item in stationList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终点站：">
          <el-select
            v-model="queryForm.endStationNames"
            collapse-tags
            multiple
            clearable
            placeholder="请选择终点站"
          >
            <el-option
              v-for="item in stationList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select
            v-model="queryForm.orderStatusList"
            collapse-tags
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购票方式：">
          <el-select
            v-model="queryForm.sourceList"
            collapse-tags
            multiple
            clearable
            placeholder="请选择购票方式"
          >
            <el-option
              v-for="item in sourceTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上车点：">
          <el-select
            v-model="queryForm.upStationNames"
            collapse-tags
            multiple
            clearable
            placeholder="请选择上车点"
          >
            <el-option
              v-for="item in stopPointList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="下车点：">
          <el-select
            v-model="queryForm.downStationNames"
            collapse-tags
            multiple
            clearable
            placeholder="请选择下车点"
          >
            <el-option
              v-for="item in stopPointList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单人：">
          <el-input
            v-model="queryForm.passengerName"
            clearable
            placeholder="请输入下单人名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系手机：">
          <el-input
            v-model="queryForm.passengerPhone"
            clearable
            placeholder="请输入下单人手机"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单时间起：">
          <el-date-picker
            v-model="queryForm.createTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="00:00:00"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="下单时间止：">
          <el-date-picker
            v-model="queryForm.createEndTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :operation="true"
      :table-height="tableHeight"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="openPopupFun(scope.scopeRow)"
          >查看</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <!-- 表格详情弹框 -->
    <div v-if="tablePopupShow" class="table-Popup">
      <div class="content">
        <!-- header -->
        <div class="header">
          <div class="text">查看</div>
          <div class="close" @click="closeFun()"></div>
        </div>

        <div class="row">
          <div class="individual">
            <div class="label">订单编号</div>
            <div class="row-val">{{ dataPopup.id }}</div>
          </div>
          <div class="individual">
            <div class="label">起点站</div>
            <div class="row-val">{{ dataPopup.startStationName }}</div>
          </div>
          <div class="individual">
            <div class="label">终点站</div>
            <div class="row-val">{{ dataPopup.endStationName }}</div>
          </div>
        </div>

        <div class="row">
          <div class="individual">
            <div class="label">上车点</div>
            <div class="row-val">{{ dataPopup.upStationName }}</div>
          </div>
          <div class="individual">
            <div class="label">下车点</div>
            <div class="row-val">{{ dataPopup.downStationName }}</div>
          </div>
          <div class="individual">
            <div class="label">下单时间</div>
            <div class="row-val">{{ dataPopup.createTime }}</div>
          </div>
        </div>

        <div class="row">
          <div class="individual">
            <div class="label">发车时间</div>
            <div class="row-val">{{ dataPopup.departureAt }}</div>
          </div>
          <div class="individual">
            <div class="label">服务费</div>
            <div class="row-val">{{ dataPopup.servicePricesPer }}</div>
          </div>
          <div class="individual">
            <div class="label">支付金额</div>
            <div class="row-val">{{ dataPopup.totalPrices }}</div>
          </div>
        </div>
        <div class="row">
          <div class="individual">
            <div class="label">退票金额</div>
            <div class="row-val">{{ dataPopup.refundTicketPrices }}</div>
          </div>
          <div class="individual">
            <div class="label">退票手续费</div>
            <div class="row-val">{{ dataPopup.refundTicketServicePrices }}</div>
          </div>
          <div class="individual">
            <div class="label">订单状态</div>
            <div class="row-val">
              {{ dataPopup.orderStatus | renderText(orderStatusList) }}
            </div>
          </div>
        </div>

        <div class="title">订单明细</div>
        <Table
          :table-data="tableData2"
          :title-name="titleName2"
          :col-class-type="true"
          :table-height="300"
        ></Table>
        <!-- footer -->
        <div class="footer">
          <div>
            <el-button @click="closeFun">取消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import {
  getCustomOrder,
  exportCustomOrder,
  getStationByKeyword,
  getCustomOrderInfo,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      tableData: [],
      tableData2: [],
      tablePopupShow: false,
      titleName: [
        {
          title: "订单编号",
          props: "id",
          width: 220,
        },
        {
          title: "业务类型",
          props: "xxx",
          width: 120,
          SpecialJudgment: (res) => {
            return "定制班线";
          },
        },
        {
          title: "下单人",
          props: "passengerName",
          width: 120,
        },
        {
          title: "下单时间",
          props: "createTime",
          width: 120,
        },
        {
          title: "购票方式",
          props: "buyTicketWay",
          width: 120,
          SpecialJudgment: (res) => {
            return res == 1 ? "网上购票" : res == 2 ? "途中购票" : "";
          },
        },
        {
          title: "起点站",
          props: "startStationName",
          width: 150,
        },
        {
          title: "终点站",
          props: "endStationName",
          width: 150,
        },
        {
          title: "上车点",
          props: "upStationName",
          width: 150,
        },
        {
          title: "下车点",
          props: "downStationName",
          width: 150,
        },
        {
          title: "发车时间",
          props: "departureAt",
        },
        {
          title: "联系手机",
          props: "orderPhone",
        },
        {
          title: "订票数",
          props: "orderCount",
        },
        {
          title: "成人票数",
          props: "adultCount",
          width: 100,
        },
        {
          title: "儿童票数",
          props: "childCount",
          width: 100,
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
          SpecialJudgment: (res) => {
            return res ? res.toFixed(2) : "";
          },
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            let list = this.orderStatusList;
            let temp = list.find((item) => item.value == res) || {};
            return temp.label;
          },
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 120,
        },
        {
          title: "最后退票时间",
          props: "updateTime",
          width: 150,
        },
      ],
      titleName2: [
        {
          title: "票号",
          props: "id",
          width: 220,
        },
        {
          title: "座位类型",
          props: "seatTypeName",
          width: 120,
        },
        {
          title: "座位号",
          props: "seatNumber",
          width: 120,
        },
        {
          title: "票种",
          props: "ticketType",
          width: 120,
          SpecialJudgment: (res) => {
            return res === 0 ? "儿童票" : res === 1 ? "成人票" : "";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
          width: 140,
        },
        {
          title: "身份证号码",
          props: "idCardNo",
          width: 160,
          SpecialJudgment: (res) => {
            return res
              ? this.decryptDes(res).replace(
                  /^(.{4})(?:\d+)(.{3})$/,
                  "$1******$2"
                )
              : "匿名";
          },
        },
        {
          title: "票价",
          props: "ticketPricesPer",
          width: 120,
        },
        {
          title: "服务费",
          props: "servicePricesPer",
          width: 120,
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            let list = this.orderStatusList;
            let temp = list.find((item) => item.value == res) || {};
            return temp.label;
          },
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 120,
        },
        {
          title: "退票时间",
          props: "refundTime",
          width: 140,
        },
      ],
      total: 0,
      queryForm: {
        pageSize: 10,
        currentPage: 1,
        id: "",
        passengerPhone: "",
        startStationNames: [],
        endStationNames: [],
        orderStatusList: [],
        sourceList: [],
        upStationNames: [],
        downStationNames: [],
        passengerName: "",
        createTime: "",
        createEndTime: "",
      },
      exportForm: {},
      dataPopup: {},
      form: {
        pageSize: 10,
        currentPage: 1,
      },
      //订单状态
      orderStatusList: [
        {
          value: 10,
          label: "待支付",
        },
        {
          value: 20,
          label: "已支付",
        },
        {
          value: 30,
          label: "订单取消",
        },
        {
          value: 40,
          label: "部分退票",
        },
        {
          value: 50,
          label: "全部退票",
        },
        {
          value: 60,
          label: "已退票",
        },
        {
          value: 70,
          label: "已检票",
        },
      ],
      //购票方式
      sourceTypeList: [
        {
          value: 1,
          label: "网上购票",
        },
        {
          value: 2,
          label: "途中购票",
        },
      ],
      //起止站点集合
      stationList: [],
      //停靠点集合
      stopPointList: [],
      tableHeight: 0,
    };
  },
  filters: {
    renderText(key, list = []) {
      let temp = list.find((item) => item.value == key) || {};
      return temp.label;
    },
  },
  mounted() {
    this.queryForm.createTime = lastTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.queryForm.createEndTime =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.queryFun();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    this.getStation(0);
    this.getStation(1);
  },
  methods: {
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    // 点击查询按钮的时候
    queryFun() {
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    //获取订单明细
    getOrderDetail() {},
    /*
    @params[type] : 车站类型：0-车场 1-停靠站
    */
    getStation(type) {
      let params = { type, status: 0 };
      getStationByKeyword(params).then((res) => {
        if (res.code == "SUCCESS") {
          let list = res.data || [];
          list = list.map((item) => {
            return { label: item.name, value: item.id };
          });
          type == 1 ? (this.stopPointList = list) : (this.stationList = list);
        }
      });
    },
    // 导出方法
    exportFun() {
      exportCustomOrder(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "定制班线订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    //打开弹窗
    openPopupFun(item) {
      this.tablePopupShow = true;
      this.dataPopup = { ...item };

      getCustomOrderInfo({ id: item.id }).then((res) => {
        console.log(res);
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data || [];
        }
      });
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.customizedLineOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
    // 关闭弹窗
    closeFun() {
      this.tablePopupShow = false;
    },
    // 表格数据渲染
    renderData() {
      getCustomOrder(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          let list = res.data.list || [];
          this.tableData = list.map((item) => {
            let titcket = (item.adultCount || 0) + (item.childCount || 0);
            return { ...item, titcket };
          });
          this.total = res.data.total;
          console.log(this.tableData);
        }
      });
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.exportForm = this.deepClone(this.queryForm);
      this.renderData();
    },
  },
};
</script>

<style scoped lang="scss">
.table-Popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    background: #ffffff;
    border-radius: 10px;
    width: 1100px;
    padding: 0 20px;
    padding-bottom: 1px;
    .row {
      height: 34px;
      width: 100%;
      line-height: 34px;
      margin: 20px 0;
      display: flex;
      .individual {
        display: flex;
        width: 33.3%;
        height: 100%;
        .label {
          width: 86px;
          padding-right: 14px;
          text-align: right;
          display: inline-block;
        }
        .row-val {
          padding: 0 10px;
          display: inline-block;
          width: calc(100% - 120px);
          border: 1px solid #d3d3d3;
          border-radius: 4px;
          color: #333333;
        }
      }
    }
    .header {
      padding: 18px 24px;
      height: 24px;
      width: calc(100% - 48px);
      border-bottom: 1px solid #d7d7d7;
      .text {
        margin-top: 8px;
        display: inline-block;
        font-size: 14px;
      }
      .close {
        float: right;
        margin-top: 10px;
        cursor: pointer;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/home/close.png") no-repeat;
        background-size: 100%;
        transform: translateY(-50%);
      }
    }
    .footer {
      padding: 10px 0px 18px 24px;
      height: 32px;
      width: calc(100% - 24px);
      border-bottom: none !important;
      border-top: 1px solid #d7d7d7;
      .el-button {
        float: right;
        margin-left: 14px;
      }
      .el-button:first-child {
        background: #336fee;
        color: #ffffff;
      }
    }
  }
}
.customizedLineOrder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.Table {
  margin-top: 16px;
}
</style>
